import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchSurveys = () => useApi(http.get('/surveys'));

export const getSurvey = id => useApi(http.get(`/surveys/${id}`));

export const getSurveyEnrollments = ({ survey, group = null }) =>
  useApi(http.get(`/surveys/${survey}/enrollments`, { params: { group } }));

export const getQuestionnaires = survey =>
  useApi(http.get(`/surveys/${survey}/questionnaires`));

export const getQuestionnairePages = (survey, questionnaire) =>
  useApi(http.get(`/surveys/${survey}/questionnaires/${questionnaire}/pages`));

export const createEnrollments = ({ group, survey }) =>
  useApi(http.post(`/surveys/${survey}/enrollments`, { group }));

export const cancelDistribution = ({ group, survey }) =>
  useApi(http.post(`/surveys/${survey}/enrollments/cancel`, { group }));

export const unlockQuestionnaire = ({
  survey,
  questionnaire,
  group,
  unlock_at,
}) =>
  useApi(
    http.post(`/surveys/${survey}/questionnaires/${questionnaire}/unlock`, {
      group,
      unlock_at,
    }),
  );
